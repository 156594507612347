import clsx from 'clsx';
import LeftArrowIcon from 'public/icons/left-arrow.svg';
import RightArrowIcon from 'public/icons/right-arrow.svg';
import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import $horizontalScroll from './HorizontalScroll.module.scss';

// Left arrow for the horizontal scroll, which is disabled and hidden when the first item
// is visible.
function LeftArrow() {
    const { scrollPrev, isFirstItemVisible } =
        React.useContext(VisibilityContext);
    return (
        <button
            disabled={isFirstItemVisible}
            type="button"
            onClick={() => {
                scrollPrev();
            }}
            className={clsx(
                $horizontalScroll.scrollButton,
                $horizontalScroll.leftArrowButton,
            )}
        >
            <LeftArrowIcon />
        </button>
    );
}

// Right arrow for the horizontal scroll, which is disabled and hidden when the last item
// is visible.
function RightArrow() {
    const { scrollNext, isLastItemVisible } =
        React.useContext(VisibilityContext);
    return (
        <button
            disabled={isLastItemVisible}
            type="button"
            onClick={() => {
                scrollNext();
            }}
            className={clsx(
                $horizontalScroll.scrollButton,
                $horizontalScroll.rightArrowButton,
            )}
        >
            <RightArrowIcon />
        </button>
    );
}

/**
 * This component is a wrapper for the react-horizontal-scrolling-menu component.
 * and we modified the LeftArrow and RightArrow components to use our own icons.
 * We also customized the $horizontalScroll for the ScrollMenu component to renders an array
 * of children in a horizontal scroll.
 *
 * The width item is not controlled by this component, but by the wrapper parent component.
 * For example, a list of ProductCard component can be wrapped by Box which controls the dimension .
 *
 * @see https://www.npmjs.com/package/react-horizontal-scrolling-menu.
 * @param fullWidth Flag to determine if container should try to use 100% of the container width.
 * @param children The children to be rendered in the horizontal scroll.
 * @param disableArrowsOnMobile Keep the arrows hidden on mobile.
 * @param disableArrowsOnDesktop Keep the arrows hidden on desktop.
 */
function HorizontalScroll({
    disableArrowsOnMobile = true,
    disableArrowsOnDesktop = false,
    disableSidebarGradient = false,
    scrollContainerClassName = undefined,
    fullBleed,
    fullWidth,
    children,
}: {
    disableArrowsOnMobile?: boolean;
    disableArrowsOnDesktop?: boolean;
    disableSidebarGradient?: boolean;
    scrollContainerClassName?: string;
    fullBleed?: boolean;
    fullWidth?: boolean;
    children: JSX.Element | JSX.Element[];
}) {
    return (
        <ScrollMenu
            LeftArrow={disableSidebarGradient ? null : LeftArrow}
            RightArrow={disableSidebarGradient ? null : RightArrow}
            scrollContainerClassName={scrollContainerClassName}
            wrapperClassName={clsx($horizontalScroll.container, {
                [$horizontalScroll.fullBleed]: fullBleed,
                [$horizontalScroll.fullWidth]: fullWidth,
                [$horizontalScroll.disableArrowsOnMobile]:
                    disableArrowsOnMobile,
                [$horizontalScroll.disableArrowsOnDesktop]:
                    disableArrowsOnDesktop,
            })}
        >
            {children}
        </ScrollMenu>
    );
}

export { HorizontalScroll };
