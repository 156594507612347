import Link from 'next/link';
import React from 'react';

import { Icon } from '@components-design-system/icon';
import { Spacer } from '@components-design-system/layout/spacer';
import { Text } from '@components-design-system/text';

import $searchBarResults from './searchBarResults.module.scss';

interface SearchBarResultsProps {
    elementId: string;
    queryId: string | undefined;
    results: (string | null)[];
    setInput: (value: string) => void;
    setIsModalOpen: (value: boolean) => void;
    trackSearchSuggestionClicked: (event: {
        query: string;
        queryId: string | undefined;
        source: string;
    }) => void;
}

const SearchBarResults = ({
    elementId,
    queryId,
    results,
    setInput,
    setIsModalOpen,
    trackSearchSuggestionClicked,
}: SearchBarResultsProps) => {
    return (
        <>
            <Spacer size={16} />
            {results.map((result) => (
                <Link
                    className={$searchBarResults.resultItem}
                    href={`/search?query=${result}`}
                    key={result}
                    onClick={() => {
                        setInput(result || '');
                        setIsModalOpen(false);
                        trackSearchSuggestionClicked({
                            query: result || '',
                            queryId,
                            source: elementId,
                        });
                    }}
                >
                    <div className={$searchBarResults.option}>
                        <Icon variant="smallSearch" />
                        <Text
                            className={$searchBarResults.label}
                            textClassName="body1medium"
                        >
                            {result}
                        </Text>
                        <Icon variant="xsmallArrowRight" />
                    </div>
                </Link>
            ))}
            <Spacer size={16} />
        </>
    );
};

export { SearchBarResults };
