interface SearchPromptClickedProps {
    label: string;
    url: string;
}

/**
 * User has clicked Inventory add product button
 */
const trackSearchPromptClicked = ({ label, url }: SearchPromptClickedProps) => {
    window?.analytics?.track('Search Prompt Clicked', {
        label,
        url,
    });
};

export { trackSearchPromptClicked };
