import clsx from 'clsx';

import { Icon } from '@components-design-system/icon';
import $typography from '@styles/typography.module.scss';

import $searchBarInput from './searchBarInput.module.scss';

interface SearchBarInputProps {
    elementId: string;
    handleFocus: () => void;
    handleKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleLeftIconClick: () => void;
    handleRightIconClick: () => void;
    input: string;
    isModalOpen: boolean;
    onSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBarInput = ({
    elementId,
    handleFocus,
    handleKeyUp,
    handleLeftIconClick,
    handleRightIconClick,
    input,
    isModalOpen,
    onSearchInputChange,
}: SearchBarInputProps) => {
    return (
        <div className={$searchBarInput.searchInputContainer}>
            <div
                className={clsx($searchBarInput.searchInputIconContainer, {
                    [$searchBarInput.clickable]: isModalOpen,
                })}
                onClick={handleLeftIconClick}
            >
                {isModalOpen ? (
                    <Icon variant="smallArrowBack" />
                ) : (
                    <Icon variant="smallSearch" />
                )}
            </div>
            <input
                autoComplete="off"
                className={clsx(
                    $searchBarInput.searchInput,
                    $typography.body1medium,
                )}
                id={elementId}
                onChange={onSearchInputChange}
                onFocus={handleFocus}
                onKeyUp={handleKeyUp}
                placeholder="Search All Products"
                value={input}
            />
            <div
                className={clsx($searchBarInput.searchInputIconContainer, {
                    [$searchBarInput.clickable]: input.length,
                })}
                onClick={handleRightIconClick}
            >
                {input.length ? <Icon variant="smallX" /> : null}
            </div>
        </div>
    );
};

export { SearchBarInput };
