import { useMemo } from 'react';

import { CategoryV2 } from '@gql/graphql';

const useParseCategories = (
    rootCategory: CategoryV2 | null,
    firstLevelSlug: string,
) => {
    const primaryCategory = useMemo(() => {
        if (
            rootCategory?.subcategories &&
            rootCategory?.subcategories.length > 0
        ) {
            const firstLevelCategory = rootCategory.subcategories.find(
                (category) => {
                    return category ? category.slug === firstLevelSlug : false;
                },
            ) as CategoryV2;

            if (
                firstLevelCategory &&
                firstLevelCategory.product_count &&
                firstLevelCategory?.product_count > 0
            ) {
                return firstLevelCategory as CategoryV2;
            }

            return null;
        }
        return null;
    }, [rootCategory, firstLevelSlug]);

    // Always parse from the root category to get the accurate data
    const secondaryCategories = useMemo(() => {
        if (
            rootCategory?.subcategories &&
            rootCategory?.subcategories.length > 0 &&
            primaryCategory !== null
        ) {
            const secondaryCategoryData = primaryCategory.subcategories?.filter(
                (category) =>
                    !!category &&
                    category.product_count &&
                    category.product_count > 0,
            );
            if (secondaryCategoryData) {
                return secondaryCategoryData as CategoryV2[];
            }
            return [];
        }
        return [];
    }, [rootCategory, primaryCategory]);

    return { primaryCategory, secondaryCategories };
};

export { useParseCategories };
