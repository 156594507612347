import clsx from 'clsx';

import { LayoutHeaderActions } from '../../../layout/layoutHeader';
import $mobileHeaderSearchResults from './mobileHeaderSearchResults.module.scss';

export interface MobileHeaderSearchResultsProps {
    label: string;
    isVisible: boolean;
}

export const MobileHeaderSearchResultsHeight =
    $mobileHeaderSearchResults.containerHeight;

const MobileHeaderSearchResults = ({
    label,
    isVisible,
}: MobileHeaderSearchResultsProps) => {
    return (
        <div
            className={clsx($mobileHeaderSearchResults.container, {
                [$mobileHeaderSearchResults.hidden]: !isVisible,
                [$mobileHeaderSearchResults.visible]: isVisible,
            })}
        >
            <LayoutHeaderActions headerText={label} />
        </div>
    );
};

export { MobileHeaderSearchResults };
