import { debounce, set } from 'lodash';
import React, {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useDashboardContext } from '@scenes/Dashboard/context';
import {
    AnalyticsOriginProperties,
    DEBOUNCE_TIME_TO_SEND_PRODUCT_VIEW_EVENTS,
} from '@utilities/tracking/constants';
import {
    TrackProductCardsSeenProps,
    trackProductCardsSeen,
} from '@utilities/tracking/dashboard/productCardsSeen/productCardsSeen';

export interface TrackProductCardSeenProps extends AnalyticsOriginProperties {
    sellableEntityVariationId: number;
    inBundle: boolean;
}

interface ProductTrackingContextType {
    trackProductCardView: (properties: TrackProductCardSeenProps) => void;
}

const ProductTrackingContext = createContext<
    ProductTrackingContextType | undefined
>(undefined);

interface ProductTrackingProviderProps {
    children: ReactNode;
}

/**
 * Reduces the view events to unique origin and originContext values with de-deplicated sellableEntityVariationIds and positions.
 *
 * @param viewEvents an array of TrackProductCardSeenProps
 * @returns an array of TrackProductCardsSeenProps with unique origin and originContext
 */
const reduceViewEvents = (
    viewEvents: TrackProductCardSeenProps[],
    cityId: number,
) => {
    const deduplicatedEvents = viewEvents.reduce((acc, product) => {
        const existingIndex = acc.findIndex(
            (existingProduct) =>
                existingProduct.origin === product.origin &&
                existingProduct.originContext === product.originContext &&
                existingProduct.sellableEntityVariationId ===
                    product.sellableEntityVariationId,
        );
        if (existingIndex === -1) {
            acc.push(product);
        }
        return acc;
    }, [] as TrackProductCardSeenProps[]);
    const mappedEvents = deduplicatedEvents.reduce((acc, product) => {
        const eventsForOrigin = acc.get(
            `${product.origin}-${product.originContext}`,
        );
        if (eventsForOrigin) {
            set(
                eventsForOrigin,
                'products',
                eventsForOrigin.products.concat({
                    position: product.position,
                    depth: product.depth,
                    sev_id: product.sellableEntityVariationId,
                    in_bundle: product.inBundle,
                }),
            );
        } else {
            acc.set(`${product.origin}-${product.originContext}`, {
                cityId,
                origin: product.origin,
                originContext: product.originContext,
                products: [
                    {
                        position: product.position,
                        sev_id: product.sellableEntityVariationId,
                        depth: product.depth,
                        in_bundle: product.inBundle,
                    },
                ],
            });
        }
        return acc;
    }, new Map<string, TrackProductCardsSeenProps>());
    return Array.from(mappedEvents.values());
};

export const ProductTrackingProvider = ({
    children,
}: ProductTrackingProviderProps) => {
    const dashboardContext = useDashboardContext();
    const [viewedProducts, setViewedProducts] = useState<
        TrackProductCardSeenProps[]
    >([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sendAccumulatedViews = useCallback(
        debounce(
            (cardsSeen: TrackProductCardSeenProps[]) => {
                if (cardsSeen.length === 0) {
                    return;
                }
                const viewEvents = reduceViewEvents(
                    cardsSeen,
                    dashboardContext?.cityId,
                );
                viewEvents.forEach((event) => {
                    trackProductCardsSeen(event);
                });
                setViewedProducts([]);
            },
            DEBOUNCE_TIME_TO_SEND_PRODUCT_VIEW_EVENTS,
            {
                trailing: true,
                leading: false,
            },
        ),
        [setViewedProducts, dashboardContext?.cityId],
    );

    useEffect(() => {
        // Cleanup function to flush any debounced calls if the component unmounts
        return () => {
            sendAccumulatedViews.flush();
        };
    }, [sendAccumulatedViews]);

    useEffect(() => {
        if (viewedProducts.length > 0) {
            sendAccumulatedViews(viewedProducts);
        }
    }, [sendAccumulatedViews, viewedProducts]);

    // Memoizing the context value to prevent unnecessary re-renders
    const trackProductCardView = useCallback(
        (properties: TrackProductCardSeenProps) => {
            setViewedProducts((prevViews) => [...prevViews, properties]);
        },
        [setViewedProducts],
    );
    const value = useMemo(
        () => ({
            trackProductCardView,
        }),
        [trackProductCardView],
    );

    return (
        <ProductTrackingContext.Provider value={value}>
            {children}
        </ProductTrackingContext.Provider>
    );
};

// Hook to use the tracking context
export const useProductTrackingContext = (): ProductTrackingContextType => {
    const context = useContext(ProductTrackingContext);
    if (context === undefined) {
        throw new Error(
            'useProductTracking must be used within a ProductTrackingProvider',
        );
    }
    return context;
};
