export const ICON_MAP = {
    arrow: '/icons/down-arrow.svg',
    bread: '/icons/icon-bread.svg',
    coin: '/icons/icon-coin.svg',
    edit: '/icons/icon-edit.svg',
    error: '/icons/icon-error.svg',
    errorFilled: '/icons/icon-error-filled.svg',
    hand: '/icons/icon-hand.svg',
    happyFace: '/icons/icon-happy-face.svg',
    jar: '/icons/icon-jar.svg',
    largeCategoryAlcohol: '/icons/large/icon-large-category-alcohol.svg',
    largeCategoryBaby: '/icons/large/icon-large-category-baby.svg',
    largeCategoryBakery: '/icons/large/icon-large-category-bakery.svg',
    largeCategoryBeverages: '/icons/large/icon-large-category-beverages.svg',
    largeCategoryCompost: '/icons/large/icon-large-category-compost.svg',
    largeCategoryDairy: '/icons/large/icon-large-category-dairy.svg',
    largeCategoryFlower: '/icons/large/icon-large-category-flower.svg',
    largeCategoryFrozen: '/icons/large/icon-large-category-frozen.svg',
    largeCategoryHealth: '/icons/large/icon-large-category-health.svg',
    largeCategoryHousehold: '/icons/large/icon-large-category-household.svg',
    largeCategoryPantry: '/icons/large/icon-large-category-pantry.svg',
    largeCategoryPersonalCare:
        '/icons/large/icon-large-category-personal-care.svg',
    largeCategoryPet: '/icons/large/icon-large-category-pet.svg',
    largeCategoryProduce: '/icons/large/icon-large-category-produce.svg',
    largeCategoryProtein: '/icons/large/icon-large-category-protein.svg',
    largeCategorySeafood: '/icons/large/icon-large-category-seafood.svg',
    largeCategoryServices: '/icons/large/icon-large-category-services.svg',
    largeCategorySnacks: '/icons/large/icon-large-category-snacks.svg',
    largeDiscover: '/icons/large/icon-large-discover.svg',
    largeReadyToEat: '/icons/large/icon-large-ready-to-eat.svg',
    largeShopDeals: '/icons/large/icon-large-shop-deals.svg',
    largeShopForYou: '/icons/large/icon-large-shop-for-you.svg',
    largeShopLocal: '/icons/large/icon-large-shop-local.svg',
    largeShopNew: '/icons/large/icon-large-shop-new.svg',
    largeThumbsDown: '/icons/large/icon-large-thumbs-down.svg',
    largeThumbsUp: '/icons/large/icon-large-thumbs-up.svg',
    leftArrow: '/icons/left-arrow.svg',
    mediumCheck: '/icons/medium/icon-med-check.svg',
    mediumRefillEmpty: '/icons/medium/icon-medium-refill-empty.svg',
    mediumRefillFull: '/icons/medium/icon-medium-refill-full.svg',
    plus: '/icons/icon-plus.svg',
    questionMark: '/icons/icon-question-mark.svg',
    recycling: '/icons/icon-recycling.svg',
    rightArrow: '/icons/right-arrow.svg',
    search: '/icons/icon-search.svg',
    smallArrowBack: '/icons/small/icon-small-arrow-back.svg',
    smallArrowCircleDown: '/icons/small/icon-small-arrow-circle-down.svg',
    smallArrowCircleLeft: '/icons/small/icon-small-arrow-circle-left.svg',
    smallArrowCircleRight: '/icons/small/icon-small-arrow-circle-right.svg',
    smallArrowCircleUp: '/icons/small/icon-small-arrow-circle-up.svg',
    smallArrowDown: '/icons/small/icon-small-arrow-down.svg',
    smallArrowLeft: '/icons/small/icon-small-arrow-left.svg',
    smallArrowRight: '/icons/small/icon-small-arrow-right.svg',
    smallAnnouncements: '/icons/small/icon-small-announcements.svg',
    smallArrowUp: '/icons/small/icon-small-arrow-up.svg',
    smallCalendar: '/icons/small/icon-small-calendar.svg',
    smallChat: '/icons/small/icon-small-chat.svg',
    smallCheck: '/icons/small/icon-small-check.svg',
    smallCheckCircle: '/icons/small/icon-small-check-circle.svg',
    smallCheckSolo: '/icons/small/icon-small-check-solo.svg',
    smallCocktail: '/icons/small/icon-small-cocktail.svg',
    smallColorCheck: '/icons/color-icons/icon-small-color-check.svg',
    smallColorError: '/icons/color-icons/icon-small-color-error.svg',
    smallColorWarning: '/icons/color-icons/icon-small-color-warning.svg',
    smallCycle: '/icons/small/icon-small-cycle.svg',
    smallDiscount: '/icons/small/icon-small-discount.svg',
    smallDiscover: '/icons/small/icon-small-discover.svg',
    smallEarth: '/icons/small/icon-small-earth.svg',
    smallPickupPlan: '/icons/small/icon-small-pickup-plan.svg',
    smallEdit: '/icons/small/icon-small-edit.svg',
    smallError: '/icons/small/icon-small-error.svg',
    smallEssentials: '/icons/small/icon-small-essentials.svg',
    smallFilter: '/icons/small/icon-small-filter.svg',
    smallFridge: '/icons/small/icon-small-fridge.svg',
    smallGlass: '/icons/small/icon-small-glass.svg',
    smallHappy: '/icons/small/icon-small-happy.svg',
    smallHeartActive: '/icons/color-icons/icon-small-heart-active.svg',
    smallHeartInactive: '/icons/small/icon-small-heart-inactive.svg',
    smallInfo: '/icons/small/icon-small-info.svg',
    smallLocal: '/icons/small/icon-small-local.svg',
    smallLock: '/icons/small/icon-small-lock.svg',
    smallMenu: '/icons/small/icon-small-menu.svg',
    smallMinus: '/icons/small/icon-small-minus.svg',
    smallMore: '/icons/small/icon-small-more.svg',
    smallNew: '/icons/small/icon-small-new.svg',
    smallPasswordHide: '/icons/small/icon-small-password-hide.svg',
    smallPasswordShow: '/icons/small/icon-small-password-show.svg',
    smallPlus: '/icons/small/icon-small-plus.svg',
    smallProfile: '/icons/small/icon-small-profile.svg',
    smallQuestionMarkCircled:
        '/icons/small/icon-small-question-mark-circled.svg',
    smallQuestionMarkCircledCayenne:
        '/icons/small/icon-small-question-mark-circled-cayenne.svg',
    smallReuse: '/icons/small/icon-small-reuse.svg',
    smallSearch: '/icons/small/icon-small-search.svg',
    smallSettings: '/icons/small/icon-small-settings.svg',
    smallShop: '/icons/small/icon-small-shop.svg',
    smallShopAll: '/icons/small/icon-small-shop-all.svg',
    smallShopBulk: '/icons/small/icon-small-shop-bulk.svg',
    smallShopPet: '/icons/small/icon-small-category-pet.svg',
    smallSort: '/icons/small/icon-small-sort.svg',
    smallTrash: '/icons/small/icon-small-trash.svg',
    smallWarning: '/icons/small/icon-small-warning.svg',
    smallX: '/icons/small/icon-small-X.svg',
    success: '/icons/icon-success.svg',
    successFilled: '/icons/icon-success-filled.svg',
    warning: '/icons/icon-warning.svg',
    xsmallArrowDown: '/icons/x-small/icon-xsmall-arrow-down.svg',
    xsmallArrowLeft: '/icons/x-small/icon-xsmall-arrow-left.svg',
    xsmallArrowRight: '/icons/x-small/icon-xsmall-arrow-right.svg',
    xsmallArrowRightBlack60:
        '/icons/x-small/icon-xsmall-arrow-right-black60.svg',
    xsmallArrowUp: '/icons/x-small/icon-xsmall-arrow-up.svg',
    xsmallEmpty: '/icons/x-small/icon-xsmall-empty.svg',
    xsmallError: '/icons/x-small/icon-xsmall-error.svg',
    xsmallPaused: '/icons/x-small/icon-xsmall-paused.svg',
    xsmallPlus: '/icons/x-small/icon-xsmall-plus.svg',
    xsmallSkip: '/icons/x-small/icon-xsmall-skip.svg',
};

type IconVariantName = keyof typeof ICON_MAP;

interface IconProps extends React.HTMLAttributes<HTMLImageElement> {
    alt?: string;
    height?: number;
    variant: IconVariantName;
    width?: number;
}

export const Icon = ({
    alt,
    height,
    variant,
    width,
    ...imgProps
}: IconProps) => {
    if (!ICON_MAP[variant]) {
        throw new Error(`No icon found for ${variant}`);
    }

    return (
        <img
            {...imgProps}
            alt={alt}
            height={height ?? 'auto'}
            src={ICON_MAP[variant]}
            width={width ?? 'auto'}
        />
    );
};
