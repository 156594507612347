import {
    Autocomplete,
    AutocompleteRenderInputParams,
    FormControl,
    InputAdornment,
    InputBase,
    InputLabel,
} from '@mui/material';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';

import { useFetchRecommendedTerms } from '@custom-hooks/search/useFetchRecommendedTerms';
import { SearchbarLoading } from '@scenes/Dashboard/components/Searchbar/SearchbarLoading';
import { SearchbarResultTerms } from '@scenes/Dashboard/components/Searchbar/SearchbarResultTerms';
import { trackSearchedRecommendedTerms } from '@utilities/tracking';

import { MobileHeaderSearchbarResultsBackground } from './components/resultsBackground';
import $mobileHeaderSearchBar from './searchBar.module.scss';

const NoPopper = () => {
    return <div className={$mobileHeaderSearchBar.noPopper} />;
};

export interface MobileHeaderSearchBarProps {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    isVisible: boolean;
}

export const MobileHeaderSearchBarHeight =
    $mobileHeaderSearchBar.containerHeight;

const MobileHeaderSearchBar = ({
    isModalOpen,
    setIsModalOpen,
    isVisible,
}: MobileHeaderSearchBarProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [input, setInput] = useState('');
    const [query, setQuery] = useState<string>('');

    const elementId = 'product-search-bar-mobile';

    const {
        error: fetchSearchTermsError,
        fetchRecommendedTerm,
        loading: loadingSearchTerms,
        queryId,
        results: searchTerms,
    } = useFetchRecommendedTerms();
    const router = useRouter();

    const PRODUCT_SEARCH_DEBOUNCE_TIME = 750;
    const MAX_TERMS_RESULT_COUNT_TO_SHOW = 10;

    // Display error if fetching search terms fails
    useEffect(() => {
        if (fetchSearchTermsError) {
            enqueueSnackbar(
                fetchSearchTermsError?.message ?? fetchSearchTermsError,
            );
        }
    }, [fetchSearchTermsError, enqueueSnackbar]);

    // Debounced query handler for search terms
    const queryChangeAsync = useCallback(
        async (queryStr: string) => {
            if (queryStr.length > 0) {
                const { data } = await fetchRecommendedTerm({
                    variables: { searchTerm: queryStr },
                });
                trackSearchedRecommendedTerms({
                    query: queryStr,
                    source: elementId,
                    returnedRecommendedTerms:
                        data?.searchTermSuggestions?.searchSuggestionList,
                });
            }
        },
        [fetchRecommendedTerm],
    );

    useEffect(() => {
        const debouncedQueryChangeAsync = debounce(
            queryChangeAsync,
            PRODUCT_SEARCH_DEBOUNCE_TIME,
        );
        debouncedQueryChangeAsync(query);
    }, [query, queryChangeAsync]);

    // Handle input change in the search bar
    const onSearchInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const inputValue = event.target.value;
        setInput(inputValue);
        setQuery(inputValue);
    };

    // Handle enter key press to perform search
    const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && input.length > 0) {
            router.push(`/search?query=${input}`);
            setIsModalOpen(false);
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isModalOpen]);

    // Filter recommended search terms
    const recommendedSearchTerms = query ? searchTerms : [];

    return (
        <div
            className={clsx($mobileHeaderSearchBar.container, {
                [$mobileHeaderSearchBar.hidden]: !isVisible,
                [$mobileHeaderSearchBar.visible]: isVisible,
            })}
        >
            <Autocomplete
                className={$mobileHeaderSearchBar.searchbar}
                data-test={elementId}
                filterOptions={(x) => x}
                forcePopupIcon={false}
                getOptionLabel={(option) => option ?? ''}
                id={elementId}
                inputValue={input}
                loading={loadingSearchTerms}
                loadingText={<SearchbarLoading />}
                noOptionsText={null}
                onClose={() => setIsModalOpen(false)}
                onKeyUp={handleKeyUp}
                onOpen={() => setIsModalOpen(true)}
                open={isModalOpen}
                options={recommendedSearchTerms.slice(
                    0,
                    MAX_TERMS_RESULT_COUNT_TO_SHOW,
                )}
                PaperComponent={MobileHeaderSearchbarResultsBackground}
                PopperComponent={NoPopper}
                renderInput={(params: AutocompleteRenderInputParams) => (
                    <FormControl
                        variant="standard"
                        className={clsx(
                            $mobileHeaderSearchBar.searchbarInputWrapper,
                            {
                                [$mobileHeaderSearchBar.hidden]: !isVisible,
                                [$mobileHeaderSearchBar.visible]: isVisible,
                            },
                        )}
                    >
                        <InputLabel
                            className="visually-hidden"
                            htmlFor={params.id}
                            {...params.InputLabelProps}
                        >
                            Search All Products
                        </InputLabel>
                        <InputBase
                            {...params.InputProps}
                            className={$mobileHeaderSearchBar.searchbarInput}
                            id={params.id}
                            inputProps={{
                                ...params.inputProps,
                                inputMode: 'search',
                            }}
                            onChange={onSearchInputChange}
                            placeholder="Search All Products"
                            startAdornment={
                                <InputAdornment
                                    position="start"
                                    className={
                                        $mobileHeaderSearchBar.startAdornment
                                    }
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            if (input.length > 0) {
                                                router.push(
                                                    `/search?query=${input}`,
                                                );
                                            }
                                        }}
                                    >
                                        <img
                                            alt="search"
                                            src="/icons/small/icon-small-search.svg"
                                        />
                                    </button>
                                </InputAdornment>
                            }
                            value={input}
                        />
                    </FormControl>
                )}
                renderOption={(_props, option) =>
                    option ? (
                        <div
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                        >
                            <SearchbarResultTerms
                                term={option}
                                queryId={queryId}
                                source={elementId}
                            />
                        </div>
                    ) : null
                }
                ListboxProps={{ style: { maxHeight: '400px' } }}
            />
        </div>
    );
};

export { MobileHeaderSearchBar };
