import clsx from 'clsx';

import { CategoryLinkRow } from '@scenes/Discover/components/CategoryLinkRow';
import {
    NavigationComponentClicked,
    NavigationComponentClickedCollectionType,
} from '@utilities/tracking/NavigationComponentClicked';

import $categoryNavigationCollapsedContainer from './categoryNavigationCollapsedContainer.module.scss';

export const CategoryNavigationCollapsedContainerHeight =
    $categoryNavigationCollapsedContainer.containerHeight;

const CategoryNavigationCollapsedContainer = ({
    isVisible,
}: {
    isVisible: boolean;
}) => {
    return (
        <div
            className={clsx($categoryNavigationCollapsedContainer.container, {
                [$categoryNavigationCollapsedContainer.hidden]: !isVisible,
                [$categoryNavigationCollapsedContainer.visible]: isVisible,
            })}
        >
            <CategoryLinkRow
                key="mobile-header-category-navigation-collapsed"
                highlightActiveCategory
                fullBleed={true}
                onClick={(name, slug) => {
                    NavigationComponentClicked({
                        componentType:
                            NavigationComponentClickedCollectionType.CategoryIcon,
                        componentName: name,
                        path: `/dashboard/inventory/${slug}`,
                    });
                }}
                viewStyle="pill"
            />
        </div>
    );
};

export { CategoryNavigationCollapsedContainer };
