import { useRouter } from 'next/router';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { AlertBannerModal } from '@custom-hooks/banner/types';
import { usePosthogAlertBannerModal } from '@custom-hooks/banner/usePosthogAlertBannerModal';
import { usePosthogTieredMovPromoBanner } from '@custom-hooks/banner/usePosthogTieredMovPromoBanner';
import { useUserAvailableCreditBanner } from '@custom-hooks/banner/useUserCreditBanner';

const emptyDefault: AlertBannerModal = {
    banner: {
        enabled: false,
    },
    modal: {
        enabled: false,
    },
};

const AlertBannerContext = React.createContext<AlertBannerModal>(emptyDefault);

const useAlertBannerContext = () => useContext(AlertBannerContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AlertBannerProvider = ({ children }: any) => {
    const [isBannerOpen, setIsBannerOpen] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const creditBanner = useUserAvailableCreditBanner();
    const posthogAlertBanner = usePosthogAlertBannerModal();
    const tieredMovPromoBanner = usePosthogTieredMovPromoBanner();

    const router = useRouter();

    const chosenBanner = useMemo(() => {
        if (isDisabled) {
            return emptyDefault;
        }
        if (tieredMovPromoBanner.banner.enabled) {
            return tieredMovPromoBanner;
        }
        if (posthogAlertBanner.banner.enabled) {
            return posthogAlertBanner;
        }
        if (creditBanner.banner.enabled) {
            return creditBanner;
        }
        return emptyDefault;
    }, [creditBanner, isDisabled, posthogAlertBanner, tieredMovPromoBanner]);

    const onBannerClose = useCallback(() => {
        chosenBanner?.banner?.onClose?.();
        setIsBannerOpen(false);
    }, [chosenBanner]);

    const onModalClose = useCallback(() => {
        chosenBanner?.modal?.onClose?.();
        setIsModalOpen(false);
        setIsBannerOpen(false);
    }, [chosenBanner]);

    const onBannerCTAClick = () => {
        chosenBanner?.banner?.onCTAClick?.();
        if (chosenBanner?.ctaLink) {
            router.push(
                `${chosenBanner.ctaLink.startsWith('/') ? '' : '/'}${
                    chosenBanner.ctaLink
                }`,
            );
        } else if (chosenBanner?.modal?.enabled) {
            setIsModalOpen(true);
        }
    };

    const contextProviderValue: AlertBannerModal = useMemo(() => {
        return {
            banner: {
                ...chosenBanner.banner,
                isOpen: isBannerOpen,
                onClose: onBannerClose,
                onCTAClick: onBannerCTAClick,
            },
            modal: {
                ...chosenBanner.modal,
                isOpen: isModalOpen,
                onClose: onModalClose,
            },
            active:
                (chosenBanner.banner.enabled &&
                    chosenBanner.banner.body &&
                    isBannerOpen &&
                    !isDisabled) ||
                false,
            ctaLink: chosenBanner.ctaLink,
            disable: () => setIsDisabled(true),
            enable: () => setIsDisabled(false),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        chosenBanner,
        isBannerOpen,
        isModalOpen,
        onBannerClose,
        onModalClose,
        onBannerCTAClick,
        router,
    ]);

    return (
        <AlertBannerContext.Provider value={contextProviderValue}>
            {children}
        </AlertBannerContext.Provider>
    );
};

export { AlertBannerProvider, useAlertBannerContext };
