export enum NavigationComponentClickedCollectionType {
    CategoryIcon = 'category-icon',
}

export type NavigationComponentClickedFunction = (args: {
    componentType: NavigationComponentClickedCollectionType;
    componentName: string;
    path: string;
    position?: number;
}) => void;

export const NavigationComponentClicked: NavigationComponentClickedFunction = ({
    componentType,
    componentName,
    path,
    position,
}) => {
    if (window && window.analytics) {
        window.analytics.track('Navigation Component Clicked', {
            componentType,
            componentName,
            path,
            position,
        });
    }
};
