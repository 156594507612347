import { useRouter } from 'next/router';

import { useFeatureFlagVariantKey } from '@custom-hooks/useFeatureFlagVariantKey';
import { FLAGS } from '@utilities/constants/featureFlags';

const useAreShopAllCategoryRowsEnabled = () => {
    const { id, default: defaultValue } = FLAGS.ENABLE_SHOP_ALL_CATEGORY_ROWS;

    const { query } = useRouter();

    const queryOverride = query?.areShopAllCategoryRowsEnabled as
        | string
        | undefined;

    const variant = useFeatureFlagVariantKey(id, defaultValue);

    let result = false;

    if (queryOverride === 'true') {
        result = true;
    } else if (queryOverride === 'false') {
        result = false;
    } else if (variant === 'test') {
        result = true;
    }

    return {
        areShopAllCategoryRowsEnabled: result,
    };
};

export { useAreShopAllCategoryRowsEnabled };
