import Link from 'next/link';

import { Icon } from '@components-design-system/icon';
import { Text } from '@components-design-system/text';
import { SearchPrompt } from '@scenes/Dashboard/components/mobileHeader/components/searchBarWithPrompts/type';
import { trackSearchPromptClicked } from '@utilities/tracking/dashboard/trackSearchPromptClicked';

import $searchBarPrompts from './searchBarPrompts.module.scss';

export const SEARCH_PROMPTS: SearchPrompt[] = [
    {
        label: 'Snacks',
        iconVariant: 'largeCategorySnacks',
        url: '/dashboard/inventory/sweets',
    },
    {
        label: 'Wellness',
        iconVariant: 'largeCategoryPersonalCare',
        url: '/dashboard/inventory/beauty',
    },
    {
        label: 'Protein',
        iconVariant: 'largeCategoryProtein',
        url: '/dashboard/inventory/meat',
    },
    {
        label: 'Dairy',
        iconVariant: 'largeCategoryDairy',
        url: '/dashboard/inventory/dairy',
    },
    {
        label: 'Produce',
        iconVariant: 'largeCategoryProduce',
        url: '/dashboard/inventory/produce',
    },
    {
        label: 'Beverages',
        iconVariant: 'largeCategoryBeverages',
        url: '/dashboard/inventory/beverages',
    },
    {
        label: 'Household',
        iconVariant: 'largeCategoryHousehold',
        url: '/dashboard/inventory/household',
    },
    {
        label: 'Pantry',
        iconVariant: 'largeCategoryPantry',
        url: '/dashboard/inventory/staples',
    },
    {
        label: 'Compost',
        iconVariant: 'largeCategoryCompost',
        url: '/dashboard/inventory/services',
    },
    {
        label: 'Bakery',
        iconVariant: 'largeCategoryBakery',
        url: '/dashboard/inventory/bread',
    },
];

interface SearchBarPromptsProps {
    searchPrompts: SearchPrompt[];
    setIsModalOpen: (value: boolean) => void;
}

const SearchBarPrompts = ({
    searchPrompts,
    setIsModalOpen,
}: SearchBarPromptsProps) => {
    const handleClick = (label: string, url: string) => {
        setIsModalOpen(false);
        trackSearchPromptClicked({ label, url });
    };

    return (
        <div className={$searchBarPrompts.searchPromptsContainer}>
            {searchPrompts.map((searchPrompt) => {
                const { label, iconVariant, url } = searchPrompt;
                return (
                    <Link
                        href={url}
                        onClick={() => handleClick(label, url)}
                        className={$searchBarPrompts.searchPrompt}
                        key={label}
                    >
                        <Text textClassName="body2bold">{label}</Text>

                        <div className={$searchBarPrompts.searchPromptIcon}>
                            <Icon height={36} variant={iconVariant} />
                        </div>
                    </Link>
                );
            })}
        </div>
    );
};

export { SearchBarPrompts };
