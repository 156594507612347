import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { GET_CATEGORY_TREE_V2_BY_SLUG } from '@custom-queries/dashboard';
import { CategoryV2 } from '@gql/graphql';

interface InventoryData {
    getCategoryV2BySlug: CategoryV2;
}

const useFetchCategories = (slug: string) => {
    const [category, setCategory] = useState<CategoryV2>();
    const { loading, error, data } = useQuery<InventoryData>(
        GET_CATEGORY_TREE_V2_BY_SLUG,
        {
            fetchPolicy: 'cache-first',
            variables: {
                slug,
            },
        },
    );

    useEffect(() => {
        if (data) {
            setCategory(data.getCategoryV2BySlug as CategoryV2);
        }
    }, [data]);

    return { category, loading, error };
};

export { useFetchCategories };
