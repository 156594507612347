import { ProductCatalogSlug } from '@gql/graphql';

export type CuratedListValues =
    | 'discover'
    | 'all'
    | 'suggestions'
    | 'local'
    | 'new-products'
    | 'sale';

export type CuratedListKeys =
    | 'DISCOVER'
    | 'ALL_PRODUCTS'
    | 'FOR_YOU'
    | 'LOCAL'
    | 'NEW_PRODUCTS'
    | 'SALE';

const curatedLists: Record<CuratedListKeys, CuratedListValues> = {
    DISCOVER: 'discover',
    ALL_PRODUCTS: 'all',
    FOR_YOU: 'suggestions',
    LOCAL: 'local',
    NEW_PRODUCTS: 'new-products',
    SALE: 'sale',
};

const isCuratedListValue = (value: string): value is CuratedListValues => {
    const curatedListValues: string[] = Object.values(curatedLists);
    return curatedListValues.includes(value);
};

const CuratedListGraphqlSlugs: Record<CuratedListValues, ProductCatalogSlug> = {
    discover: ProductCatalogSlug.Discover,
    all: ProductCatalogSlug.AllProducts,
    suggestions: ProductCatalogSlug.ForYou,
    local: ProductCatalogSlug.Local,
    'new-products': ProductCatalogSlug.NewProducts,
    sale: ProductCatalogSlug.Sale,
};

const curatedListDisplayNameMap: Partial<Record<CuratedListValues, string>> = {
    discover: 'Picked For You',
    'new-products': 'New',
    sale: 'Deals',
    local: 'Local',
};

export {
    curatedLists,
    curatedListDisplayNameMap,
    CuratedListGraphqlSlugs,
    isCuratedListValue,
};
