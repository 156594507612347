export const FLAGS = {
    // TODO: We will store the feature flags (name and default value) here.
    ONBOARDING_CURATED_PRODUCTS_EXPERIMENT: {
        id: 'onboarding_curated_products_experiment',
        default: 'test',
    },
    ALERT_BANNER: {
        id: 'alert-banner',
    },
    NEW_SORT_ORDER: {
        id: 'new-sort-order',
        default: 'control',
    },
    CUSTOM_STARTER_BUNDLE_EXPERIMENT: {
        id: 'custom_starter_bundles_experiment',
        default: false,
    },
    ENABLE_SEARCH_PROMPTS: {
        id: 'enable-search-prompts',
        default: 'control',
    },
    ENABLE_SHOP_ALL_CATEGORY_ROWS: {
        id: 'enable-shop-all-category-rows',
        default: 'control',
    },
    ENABLE_NEW_PAGE_CATEGORY_ROWS: {
        id: 'enable-new-page-category-rows',
        default: 'control',
    },
    TIERED_MOV_PROMO: {
        id: 'tiered-mov-promo',
        default: null,
    },
    PRODUCT_TAGS_BEST_SELLER: {
        id: 'product-tags-best-seller-experiment-redo',
        default: 'control',
    },
    PRODUCT_TAGS_CONFIGURABLE: {
        id: 'product-tags-configurable',
        default: 'control',
    },
    REFERRALS_TOF_DASHBOARD: {
        id: 'referrals-tof-dashboard',
        default: false,
    },
    ALCOHOL_COLLECTION: {
        id: 'alcohol-collection',
        default: false,
    },
    PREFERENCE_QUIZ_PROMPT_BANNER: {
        id: 'preference-quiz-prompt-banner',
        default: false,
    },
    REUSE_DASHBOARD: {
        id: 'reuse-dashboard',
        default: 'control',
    },
    ONE_TIME_ADD: {
        id: 'one-time-add',
        default: 'control',
    },
    REFILL_RECOMMENDATIONS_VARIANTS: {
        id: 'refill-recommendations-variants-v2',
        default: 'control',
    },
    ANNOUNCEMENTS_INBOX: {
        id: 'announcements-inbox',
        default: 'control',
    },
};
