import { useRouter } from 'next/router';

import { useFeatureFlagVariantKey } from '@custom-hooks/useFeatureFlagVariantKey';
import { FLAGS } from '@utilities/constants/featureFlags';

const useReuseDashboard = () => {
    const { id, default: defaultValue } = FLAGS.REUSE_DASHBOARD;

    const { query } = useRouter();

    const queryOverride = query?.showReuseDashboard as string | undefined;

    const variant = useFeatureFlagVariantKey(id, defaultValue);

    let result = false;

    if (queryOverride === 'true') {
        result = true;
    } else if (queryOverride === 'false') {
        result = false;
    } else if (variant === 'test') {
        result = true;
    }

    return {
        showReuseDashboard: result,
    };
};

export { useReuseDashboard };
