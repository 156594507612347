export enum ProductDetailPageClickedOriginType {
    Dashboard = 'dashboard',
    SearchBar = 'search bar',
    SearchPage = 'search-page',
    Inventory = 'inventory',
    ForYou = 'for-you',
    TrendingProducts = 'trending-products',
    Discover = 'discover-home',
    CuratedCollection = 'curated-collection',
    PDPRecommendation = 'pdp-recommendation',
    PDPInDrawerRecommendation = 'pdp-in-drawer-recommendation',
    PDPAddOn = 'pdp-add-on',
    ServicesLanding = 'services-landing',
}

type ProductDetailPageClickedFunction = (
    productId: number,
    origin: ProductDetailPageClickedOriginType,
    options?: {
        collectionName?: string;
        productPosition?: number;
        isPinned?: boolean;
        city_id?: number;
        query_id?: string;
        pdp_product_id?: number;
        pqvProductId?: number;
    },
) => void;

/**
 * Track event when a member clicks to view a PDP page.
 * @see https://www.notion.so/Product-Detail-Page-Clicked-c33c110d470b485d896d448d1ffa2a58?pvs=4
 */
const ProductDetailPageClicked: ProductDetailPageClickedFunction = (
    productId,
    origin,
    options = {},
) => {
    if (!options.query_id) {
        // eslint-disable-next-line no-param-reassign
        delete options.query_id;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Product Detail Page Clicked', {
            productId,
            origin,
            ...options,
        });

    // Store origin in sessionStorage for use in Product Action Clicked event
    sessionStorage.setItem('pdpClickedOrigin', origin);
};

export default ProductDetailPageClicked;
