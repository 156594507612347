import { ClickOrigin } from '@utilities/tracking/constants';

interface ProductProperties {
    sev_id: number;
    position?: number;
    depth?: number;
    in_bundle: boolean;
}

export interface TrackProductCardsSeenProps {
    cityId: number; // used to send event to proper Algolia index
    origin: ClickOrigin;
    originContext: number | string;
    products: ProductProperties[];
}

export const trackProductCardsSeen = (
    properties: TrackProductCardsSeenProps,
) => {
    window?.analytics?.track('Product Cards Seen', properties);
};
